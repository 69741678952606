<template>
  <div
    class="d-flex align-items-center justify-content-center login-background"
  >
    <b-overlay :show="loading" rounded="sm">
      <b-card border-variant="light" class="login-card mx-3 mx-sm-0">
        <div class="d-flex justify-content-between mb-3">
          <b-card-title class="mb-0 mr-4">
            {{ $t("resetPasswordEmail.title") }}
          </b-card-title>
          <img :src="logo" alt="Qognite Logo" class="logo" />
        </div>

        <div v-if="emailSet">
          <p>{{ $t("resetPasswordEmail.successMessage") }}</p>
          <b-link to="/login">
            {{ $t("resetPasswordEmail.back") }}
          </b-link>
        </div>

        <div v-else>
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(resetPassword)">
              <ValidationProvider
                v-slot="{ errors, invalid }"
                name="E-mail Address"
                rules="required|email"
              >
                <b-form-group
                  id="group-email"
                  :label="$t('resetPasswordEmail.email')"
                  label-for="e-mail"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    :state="getValidationState(errors)"
                  />
                  <b-form-invalid-feedback id="live-feedback-email">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>

                <div class="d-flex align-items-center justify-content-between">
                  <b-link to="/login">
                    {{ $t("resetPasswordEmail.back") }}
                  </b-link>
                  <b-button
                    :disabled="invalid || loading"
                    class="gradient-button-outline"
                    pill
                    type="submit"
                  >
                    {{ $t("resetPasswordEmail.reset") }}
                  </b-button>
                </div>
              </ValidationProvider>
            </b-form>
          </ValidationObserver>
        </div>
      </b-card>
    </b-overlay>
    <Footer />
  </div>
</template>

<script>
import { ValidationProvider } from "vee-validate";
import { ValidationObserver } from "vee-validate";
import { extend } from "vee-validate";
import { email, required } from "vee-validate/dist/rules";
import qognite_logo from "@/assets/images/qognite-logo.svg";
import Footer from "@/components/Footer";

extend("email", email);
extend("required", {
  ...required,
  message: "{_field_} is required",
});

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    Footer,
  },
  name: "ResetPaswordEmail",
  data() {
    return {
      email: "",
      emailSet: false,
      logo: qognite_logo,
      loading: false,
    };
  },
  methods: {
    async resetPassword() {
      try {
        this.loading = true;
        await this.$http.post(
          this.$cfg.BASE_IDENTITY_VERSION_URL + "/reset-password",
          {
            activeMainEmail: this.email,
          }
        );
        this.emailSet = true;
      } catch (err) {
        if (err.response) console.log(err.response);
      } finally {
        this.loading = false;
      }
    },
    getValidationState(errors) {
      return errors[0] ? false : null;
    },
  },
};
</script>
